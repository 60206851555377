import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import AxiosOICStat from '../../AxiosOICStat';
import { Select, Button, Row, Col, Tooltip, Checkbox, Card, Collapse, Typography, Divider, TreeSelect, Tree, Input, message, Form, Modal } from 'antd';
import { ConsoleSqlOutlined, InfoCircleOutlined } from '@ant-design/icons';
import DataTable from '../../components/DataTable';
import { useSelector } from 'react-redux';
import "../../style/external/query.css";
import { IndicatorShowExternal } from 'components/modals/IndicatorShowExternal';
import { formatDataTableValue } from 'components/Utils';
import ColumnBasedLayout from '../../components/ColumnBasedLayout';
import { useTranslation } from 'react-i18next';
import { MIN_THRESHOLD, MID_THRESHOLD, MAX_THRESHOLD } from './dataScaleConstantsConfig';

const { Search } = Input;
const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;
const { SHOW_PARENT } = TreeSelect;
const customPanelStyle = {
    fontFamily: 'Roboto, sans-serif',
};
const selectStyle = {
    fontFamily: 'Roboto, sans-serif',
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
}

const selectTitleStyle = {
    fontFamily: 'Roboto, sans-serif',
    width: '130px',
    maxWidth: '300px',
    display: 'flex',
    marginRight: '10px',

}

const textStyle = {
    lineHeight: '22px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '300',
    fontSize: '14px',
    marginLeft: '10px'
};

const GoalSelectionModal = ({ visible, onCancel, onOk }) => {
    const [form] = Form.useForm();

    const handleOk = () => {
        form
            .validateFields()
            .then(values => {
                form.resetFields();
                onOk(values.goal);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <Modal
            visible={visible}
            title="Select Goal"
            onCancel={onCancel}
            onOk={handleOk}
        >
            <Form form={form} layout="vertical" name="goalSelectionForm">
                <Form.Item
                    name="goal"
                    label="Goal"
                    rules={[{ required: true, message: "Please Select a Goal!" }]}
                >
                    <Select placeholder="Select a Goal">
                        <Option value="1">SDG1</Option>
                        <Option value="2">SDG2</Option>
                        <Option value="3">SDG3</Option>
                        <Option value="4">SDG4</Option>
                        <Option value="5">SDG5</Option>
                        <Option value="6">SDG6</Option>
                        <Option value="7">SDG7</Option>
                        <Option value="8">SDG8</Option>
                        <Option value="9">SDG9</Option>
                        <Option value="10">SDG10</Option>
                        <Option value="11">SDG11</Option>
                        <Option value="12">SDG12</Option>
                        <Option value="13">SDG13</Option>
                        <Option value="14">SDG14</Option>
                        <Option value="15">SDG15</Option>
                        <Option value="16">SDG16</Option>
                        <Option value="17">SDG17</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};



export function Query({ auto_search = false }) {
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);

    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const countriesState = useSelector((state) => state.countries);
    const indicatorsState = useSelector((state) => state.privateIndicators);

    const categoriesState = useSelector((state) => state.categories);
    const unitsState = useSelector((state) => state.units);
    const [regionMappingsData, setRegionMappingsData] = useState([]);
    const [countryMap, setCountryMap] = useState({});
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [exportData, setExportData] = useState([]);

    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showModalVisible, setShowModalVisible] = useState(false);

    const [treeData, setTreeData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [expandedKeys, setExpandedKeys] = useState([]);
    const originalTreeData = useRef();

    const [indicatorOrder, setIndicatorOrder] = useState('by Country then Indicator');
    const [yearOrder, setYearOrder] = useState('Ascending');
    const [decimalSymbol, setDecimalSymbol] = useState('.');
    const [decimalPlaces, setDecimalPlaces] = useState(2);
    const [lastYearAvailable, setLastYearAvailable] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [yearRange, setYearRange] = useState([]);
    const [colYearView, setcolYearView] = useState(false);
    const [cols, setCols] = useState([]);
    const [goalSelectionVisible, setGoalSelectionVisible] = useState(false);
    const [catCodeToDownload, setCatCodeToDownload] = useState(null);
    const [categories, setCategories] = useState([]);
    const [oldcheckedKeys, setOldCheckedKeys] = useState([]);
    const [indicatorKeys, setIndicatorKeys] = useState([]);
    const [sdgMapping, setSdgMapping] = useState({});
    const [searchCountryValue, setSearchCountryValue] = useState('');
    const [filteredCountries, setFilteredCountries] = useState([]);
    const { t } = useTranslation();
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
    const { Title } = Typography;

    useEffect(() => {
        // Assume these are the endpoints to fetch platform and mission data
        if (auto_search) {
            handleSearch();
        }
    }, [auto_search]);

    useEffect(() => {
        // sort the selected values
        setSelectedCountries(selectedCountries.sort((a, b) => parseInt(a) - parseInt(b)));
        setSelectedIndicators(selectedIndicators.sort((a, b) => parseInt(a) - parseInt(b)));
        setSelectedYears(selectedYears.sort((a, b) => parseInt(a) - parseInt(b)));

    }, [selectedCountries, selectedIndicators, selectedYears]);

    useEffect(() => {
        // Fetch the region mappings when the component is mounted
        const fetchRegionMappings = async () => {
            try {
                const response = await AxiosOICStat.get('/region-mappings/'); // Adjust the endpoint as needed
                setRegionMappingsData(response.data); // Assuming the response body will be the array of region mappings
            } catch (error) {
                console.error('Failed to fetch region mappings:', error);
                // Handle errors, e.g., show notification
            }
        };

        fetchRegionMappings();
    }, []);


    const getCategoriesAndIndicators = async () => {
        try {
            const categoriesResponse = await AxiosOICStat.get('/indicators/categories/');
            const sdgResponse = await AxiosOICStat.get('/indicators/sdg-goals/');
            setCategories(categoriesResponse.data);

            const sdgMapping = sdgResponse.data.reduce((acc, sdg) => {
                acc[sdg.indicator_id] = sdg.goal;
                return acc;
            }, {});
            setSdgMapping(sdgMapping);

        } catch (error) {
            console.error('Failed to fetch categories and indicators:', error);
        }
    };

    useEffect(() => {
        getCategoriesAndIndicators();
    }, []);


    useEffect(() => {
        resetTreeData();
    }, [categories, categoriesState, indicatorsState]);

    const resetTreeData = () => {
        const TreeNodeTitle = React.memo(({ title, onShow }) => (
            <Tooltip title="More info about the indicator" onClick={onShow}>
                {title}
                <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
        ));
        const filteredCategories = categoriesState?.value
        ? Object.fromEntries(
            Object.entries(categoriesState.value).filter(([key, category]) => category.is_private === 0)
          )
        : {};
        
    
        const newTree = categories.map(category => {
            const parentCategory = filteredCategories[category.cat_code];
            let childIndicators = [];
            let parentLength = 0;
            if (category.cat_code === 27) { // Assuming 27 is the SDG category code
                // Group indicators under SDG goals
                const sdgGroupedIndicators = {};
                category.indicators.forEach(indicatorCode => {
                    const indicatorData = indicatorsState.value[indicatorCode];
                    if (indicatorData && sdgMapping[indicatorData.ind_code]) {
                        const goalNumber = sdgMapping[indicatorData.ind_code];
                        if (!sdgGroupedIndicators[goalNumber]) {
                            sdgGroupedIndicators[goalNumber] = [];
                        }
                        sdgGroupedIndicators[goalNumber].push({
                            title: <TreeNodeTitle title={`${indicatorData.ind_name_eng} [${unitsState.value[indicatorData.unit_code]?.unit_name_eng || 'No unit'}]`} onShow={() => handleShow(indicatorData)} />,
                            titleText: indicatorData.ind_name_eng,
                            key: `${category.cat_code}-${indicatorData.ind_code}`,
                            searchKey: indicatorData.ind_code,  // Non-unique key used for operational tasks
                            filterView: true
                        });
                    }
                });
    
                parentLength = 760;
                
                childIndicators = Object.entries(sdgGroupedIndicators).map(([goal, indicators]) => {
                    const sortedIndicators = indicators.sort((a, b) => a.titleText.localeCompare(b.titleText));
    
                    return {
                        title: `Goal ${goal} (${indicators.length})`,
                        key: `${category.cat_code}-goal-${goal}`,
                        children: sortedIndicators
                    }
                });
            } else {
                childIndicators = Array.isArray(category.indicators) ? category.indicators.map(indicatorCode => {
                    const indicatorData = indicatorsState.value[indicatorCode];
                    if (indicatorData) {
                        return {
                            title: <TreeNodeTitle title={`${indicatorData.ind_name_eng} [${unitsState.value[indicatorData.unit_code]?.unit_name_eng || 'No unit'}]`} onShow={() => handleShow(indicatorData)} />,
                            titleText: indicatorData.ind_name_eng,
                            key: `${category.cat_code}-${indicatorData.ind_code}`,
                            searchKey: indicatorData.ind_code,  // Non-unique key used for operational tasks
                            filterView: true
                        };
                    }
                    return null;
                }).filter(indicator => indicator !== null).sort((a, b) => a.titleText.localeCompare(b.titleText)) : [];
    
                parentLength = childIndicators.length;
            }
            if (!parentCategory) return null; // Skip undefined parent categories

            return {
                title: <div style={{display: 'flex', alignItems: 'center', justifyContent: "space-evenly", width: '500px'}}>
                    <span style={{ fontWeight: '700', flex: "2", maxWidth: "300px" }}>{parentCategory?.cat_name_eng + (parentLength > 0 ? ` (${parentLength})` : '')}</span>
                    <Button style={{ fontSize: '12px', flex: "1", width:"200px" }} onClick={(e) => handleDownloadEntireDataset(e, parentCategory?.cat_code)}>Download Entire Dataset</Button>
                </div>,
                titleText: parentCategory?.cat_name_eng,
                key: parentCategory?.cat_code,
                children: childIndicators,
            };
        }).filter(item => item !== null);
    
        originalTreeData.current = newTree;
        setTreeData(newTree?.sort((a, b) => (a.titleText || '').localeCompare(b.titleText || '')));
        console.log("tree",newTree);
        const newSelectedIndicators = selectedIndicators.filter(selectedKey =>
            newTree.some(category => category.children.some(indicator => indicator.searchKey === selectedKey))
        );
        setSelectedIndicators(newSelectedIndicators);
    };

    

    const handleDownloadCategory = (catCode, goal = null) => {
        const endpoint = goal ? `data/get-category-csv/${catCode}?goal=${goal}` : `data/get-category-csv/${catCode}`;
        AxiosOICStat.get(endpoint, { responseType: 'blob' })
            .then(response => {
                // Step 1: Convert Blob to text (CSV format)
                const reader = new FileReader();
                reader.onload = () => {
                    let csvData = reader.result; 
    
                    csvData = removeWorldRows(csvData);
                    
                    const modifiedBlob = new Blob([csvData], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(modifiedBlob);
    
                    const link = document.createElement('a');
                    link.href = url;
    
                    const contentDisposition = response.headers['content-disposition'];
                    let fileName = 'sesric_data.csv';
                    if (contentDisposition) {
                        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
                        if (fileNameMatch && fileNameMatch.length === 2) {
                            fileName = fileNameMatch[1];
                        }
                    }
    
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
    
                    window.URL.revokeObjectURL(url);
                };
                
                reader.readAsText(response.data);
            })
            .catch(err => {
                console.error('Error downloading category CSV:', err);
                message.error('Error downloading category CSV');
            });
    };
       
    const removeWorldRows = (csvData) => {
        let rows = csvData.split('\n');  
        let filteredRows = rows.filter(row => {
            let cols = row.split(','); 
            return !cols[0].startsWith('"World"');  
        });
        return filteredRows.join('\n');   
    };

    const handleGoalSelectionOk = (goal) => {
        setGoalSelectionVisible(false);
        handleDownloadCategory(catCodeToDownload, goal);
    };

    const handleDownloadEntireDataset = async (event, cat_code) => {
        event.stopPropagation();

        if (cat_code === 27) {
            setCatCodeToDownload(cat_code);
            setGoalSelectionVisible(true);
        } else {
            handleDownloadCategory(cat_code);
        }
    };

    const handleTreeSearch = (event, value) => {
        event.preventDefault();
        setSearchValue(value);

        const TreeNodeTitle = React.memo(({ title, onShow }) => (
            <Tooltip title="More info about the indicator" onClick={onShow}>
                {title}
                <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
        ));

        if (value.trim().length < 3) {
            if (!value.trim()) {
                resetTreeData();
                setExpandedKeys([]);
                return;
            }
            return;
        }

        const searchLower = value.toLowerCase();
        const filteredIndicators = new Set();
        const newTreeData = [];

        categories.forEach(category => {
            category.indicators.forEach(indicatorCode => {
                const indicatorData = indicatorsState.value[indicatorCode];
                if (indicatorData && indicatorData.ind_name_eng.toLowerCase().includes(searchLower)) {
                    if (!filteredIndicators.has(indicatorData.ind_code)) { // Check uniqueness by indicator code
                        filteredIndicators.add(indicatorData.ind_code);
                        newTreeData.push({
                            title: <TreeNodeTitle title={indicatorData.ind_name_eng + " [" + (unitsState.value[indicatorData.unit_code]?.unit_name_eng || 'No unit') + "]"} onShow={() => handleShow(indicatorData)} />,
                            key: `${category.cat_code}-${indicatorData.ind_code}`,
                            searchKey: indicatorData.ind_code,  // Non-unique key for operational tasks
                            titleText: indicatorData.ind_name_eng,
                            filterView: true
                        });
                    }
                }
            });
        });

        setTreeData(newTreeData.sort((a, b) => a.titleText.localeCompare(b.titleText)));
    };

    const handleShowModalCancel = () => {
        setShowModalVisible(false);
    };
    const handleShow = useCallback((indicatorData) => {
        setSelectedRecord(indicatorData);
        setShowModalVisible(true);
    }, [])



    const onExpand = (expandedKeysValue, { node, expanded }) => {
        const key = node.key;

        // Determine if we are expanding or collapsing
        if (expanded) {
            // Add the node's key to the expandedKeys array if it's not already there
            setExpandedKeys(prevKeys => [...new Set([...prevKeys, key])]);
        } else {
            // Remove the node's key from the expandedKeys array
            setExpandedKeys(prevKeys => prevKeys.filter(k => k !== key));
        }
    };


    const generateYearRange = (startYear, endYear) => {
        return Array.from({ length: endYear - startYear + 1 }, (_, i) => endYear - i);
    };

    const yearsList = generateYearRange(1989, new Date().getFullYear());



    const onYearChange = (checked, year) => {
        setSelectedYears(prev => checked ? [...prev, { year: year }] : prev.filter(y => y.year !== year));
    };

    const onCheck = (checkedKeys, info) => {
        //console.log(info); // Debug output to understand what you receive
        //console.log(checkedKeys); // This should reflect all currently checked keys, including after checks and unchecks

        const mode = info.node.checked ? 'uncheck' : 'check';

        const filtered = info.node.filterView ? true : false;


        let keys = [];

        if (filtered) {
            // combine the old checked keys with the new checked keys
            keys = [...oldcheckedKeys, ...checkedKeys];
        } else {
            keys = checkedKeys;
        }

        let checkedIndicators = [];

        if (mode === 'uncheck') {
            console.log('uncheck');
            const searchKey = info.node.searchKey;
            let categoryKey;
            if (!searchKey) {
                categoryKey = info.node.key;
            }
            console.log("categoryKey", categoryKey);


            if (categoryKey) {
                // find all search keys
                console.log(categories)
                let currentCategory;
                if (typeof categoryKey === "string" && categoryKey.includes('goal')) {
                    const goal = categoryKey.split('-')[2];
                    console.log("goal", goal);
                    console.log("sdg mapping", sdgMapping);
                    currentCategory = categories.find(category => category.cat_code === 27).indicators.filter(indicator => sdgMapping[indicator] == goal);
                } else {
                    currentCategory = categories.find(category => category.cat_code === categoryKey).indicators;
                }
                console.log("currentCategory", currentCategory);
                const searchKeys = currentCategory.map(indicator => indicator.toString());
                console.log("searchKeys", searchKeys);
                // use the search keys to filter out the keys that are not related to the search key
                keys = keys.filter(key => typeof key === 'string').filter(key => !searchKeys.includes(key.split('-')[1]));
                console.log("newCheckedKeys", keys);
            } else {
                keys = keys.filter(key => typeof key === 'string').filter(key => !key.includes("-" + searchKey));
            }

            console.log("newCheckedKeys", keys);
            checkedIndicators = keys.map(key => parseInt(key.split('-')[1]));
        } else {
            checkedIndicators = keys.filter(key => typeof key === 'string').map(key => parseInt(key.split('-')[1]));
        }

        // Set selected indicators directly from checkedKeys, assuming these keys are your indicator search keys
        // Convert checkedKeys to just a set of searchKeys if your keys are structured with parent-child data
        checkedIndicators = checkedIndicators.filter(value => !isNaN(value));
        const uniqueValues = Array.from(new Set(checkedIndicators));

        setSelectedIndicators(uniqueValues);
        //console.log(uniqueValues); // Debug to see what you are setting

        setOldCheckedKeys(keys);

        // Since we depend on uniqueValues to drive the UI, let's calculate which category-indicator pairs need to be active
        if (uniqueValues.length > 0) {
            let tempIndicatorKeys = [];
            uniqueValues.forEach(indicator => {
                categories.forEach(category => {
                    if (category.indicators && category.indicators.includes(indicator)) {
                        tempIndicatorKeys.push(category.cat_code + '-' + indicator);
                    }
                });
            });
            //console.log(tempIndicatorKeys); // Debug to see what pairs you are calculating
            setIndicatorKeys(tempIndicatorKeys);
        } else {
            // If nothing is checked, we should reset the indicator keys
            setIndicatorKeys([]);
        }
    };

    const handleDataFormat = (data, forExport = false) => {
        //console.log(language)
        //console.log(countriesState)
        //console.log(indicatorsState)
        //console.log(unitsState)

        let langUpdatedList = [];

        let updatedData = data.map((element, index) => {
            element.key = index;
        
            
            if (!forExport) {
                if(language == "en"){
                    element.ind_name = indicatorsState.value[element.ind_code] && indicatorsState.value[element.ind_code].ind_name_eng;
                    element.c_name = countriesState.value[element.c_code].c_short_name_eng;
                    element.u_name = unitsState.value[element.u_code].unit_name_eng;
                    element.cat_name = categoriesState.value[indicatorsState.value[element.ind_code].cat_code]?.cat_name_eng;
                }
                
                else if(language == "fr"){
                    element.ind_name = indicatorsState.value[element.ind_code] && indicatorsState.value[element.ind_code].ind_name_fr;
                    element.c_name = countriesState.value[element.c_code].c_short_name_fr;
                    element.u_name = unitsState.value[element.u_code].unit_name_fr;
                    element.cat_name = categoriesState.value[indicatorsState.value[element.ind_code].cat_code]?.cat_name_fr;
                }
                else if(language == "ar"){
                    element.ind_name = indicatorsState.value[element.ind_code] && indicatorsState.value[element.ind_code].ind_name_arb;
                    element.c_name = countriesState.value[element.c_code].c_short_name_arb;
                    element.u_name = unitsState.value[element.u_code].unit_name_arb;
                    element.cat_name = categoriesState.value[indicatorsState.value[element.ind_code].cat_code]?.cat_name_arb;
                }
                else{
                    element.ind_name = indicatorsState.value[element.ind_code] && indicatorsState.value[element.ind_code].ind_name_eng;
                    element.c_name = countriesState.value[element.c_code].c_short_name_eng;
                    element.u_name = unitsState.value[element.u_code].unit_name_eng;
                    element.cat_name = categoriesState.value[indicatorsState.value[element.ind_code].cat_code]?.cat_name_eng;
                }
                
                

                //console.log(entry);
                
            }
            else {
                element.years.forEach((value, index) => {
                    element.years[index] = formatDataTableValue(value, decimalSymbol, decimalPlaces);
                });
            }
            //console.log(entry)
            //const pushedEntry = entry
            //langUpdatedList.push(pushedEntry)
            //console.log(element);
            return element;
        });


        if (yearOrder === 'Descending') {
            updatedData = updatedData.sort((a, b) => b.d_year - a.d_year);
        }
        if (indicatorOrder === 'by Indicator then Country') {
            updatedData = updatedData.sort((a, b) => a.ind_name.localeCompare(b.ind_name) || a.c_name.localeCompare(b.c_name));
        } else {
            updatedData = updatedData.sort((a, b) => a.c_name.localeCompare(b.c_name) || a.ind_name.localeCompare(b.ind_name));
        }
        //console.log("LANG UPDATED",langUpdatedList)
        return updatedData; //LANG UPDATES SHOULD BE SENT

    }


    function transformData(logs) {
        const transformed = [];
        let i = 0;  // Initialize i outside the loop

        // sort the logs by c_code and ind_code
        logs.sort((a, b) => a.ind_code - b.ind_code || a.c_code - b.c_code);

        while (i < logs.length) {
            const log = logs[i];
            
            let newLog = {
                c_code: log.c_code,
                c_name: log.c_name,
                cat_name: log.cat_name,
                d_date: log.d_date,
                ind_code: log.ind_code,
                ind_name: log.ind_name,
                key: log.key,
                u_code: log.u_code,
                u_name: log.u_name
            }
            
            let j = i;
            while (j < logs.length && logs[j].c_code == newLog.c_code && logs[j].ind_code == newLog.ind_code) {
                newLog[logs[j].d_year] = logs[j].d_value;
                if (!newLog.d_date) {
                    newLog.d_date = logs[j].d_date;
                }
                j++;
            }
            transformed.push(newLog);
            i = j;
        }

        // sort transformed data by indicator order state
        if (indicatorOrder === 'by Indicator then Country') {
            transformed.sort((a, b) => a.ind_name.localeCompare(b.ind_name) || a.c_name.localeCompare(b.c_name));
        } else {
            transformed.sort((a, b) => a.c_name.localeCompare(b.c_name) || a.ind_name.localeCompare(b.ind_name));
        }
        //console.log(transformed);
        return transformed;
    }

    const transformLastYearData = (data) => {
        data.forEach((item, index) => {
            if(unitsState.value[item.u_code] != undefined && categoriesState.value[item.cat_code] != undefined){
                item.u_name = unitsState.value[item.u_code].unit_name_eng;
                item.cat_name = categoriesState.value[item.cat_code].cat_name_eng;
            }
        });
    }

    const handleSearch = () => {
        var dataScale = selectedIndicators.length*selectedYears.length*selectedCountries.length;
        if (dataScale > MAX_THRESHOLD) {
            message.error('The scale of data you select is large. Please refine your selection.'); //Threshold values are imported from './dataScaleConstantsConfig.js
        } else {
            if(dataScale < MIN_THRESHOLD){}
            else if(dataScale > MIN_THRESHOLD && dataScale < MID_THRESHOLD){
                message.warning('This operation may take approximately 1 minute.');
            }
            else{
                message.warning('This operation may take 1-2 minutes.');
            }
    
            const formattedYears = [];
            var parameter_check = false

            if(selectedCountries.length == 0){
                message.warning("You should choose at least one country")
                parameter_check = true
            }
            if(selectedIndicators.length == 0){
                message.warning("You should choose at least one indicator")
                parameter_check = true
            }
            if(selectedYears.length == 0 && !lastYearAvailable){
                message.warning("You should choose at least one year")
                parameter_check = true
            }

            selectedYears.forEach(item => {
                if (item && item.hasOwnProperty('year')) { // Check if the item has a 'year' property
                    formattedYears.push(item.year);
                } else {
                    // Handle the case where the item is not what you expect
                    console.warn('Invalid item in selectedYears:', item);
                }
            });

            const formattedCountries = selectedCountries.map(country => country.split(' - ')[0]);
            //const formattedIndicators = selectedIndicators.map(indicator => indicator.split(' - ')[0]);
            const formattedIndicators = selectedIndicators;
            if(lastYearAvailable){
                setLoading(true);
                AxiosOICStat.post('/data/get_latest_data',{
                    countries: formattedCountries,
                    indicators: formattedIndicators,
                    first_year_range: [],
                    last_year_range: []  
                   }).then(res => {
                        //console.log(res.data);
                        setCols(["c_name", "cat_name", "ind_name", "u_name", "d_date", "d_year", "d_value"])
                        setcolYearView(!lastYearAvailable);
                        setSearchResults(res.data);
                        transformLastYearData(res.data);
                        setLoading(false);
                   })
            }
            else if(!parameter_check){
                setLoading(true);
                AxiosOICStat.post('/data/query', {
                    countries: formattedCountries,
                    indicators: formattedIndicators,
                    years: formattedYears,
    
                }).then(response => {
                    //console.log(response.data);
                    let updatedData = handleDataFormat(response.data);

                    //console.log("BEFORE TRANSFORM",updatedData)
                    if (!lastYearAvailable) {
                        updatedData = transformData(updatedData);
                        setCols(["c_name", "cat_name", "ind_name", "u_name", "d_date"]);
                    } else {
                        setCols(["c_name", "cat_name", "ind_name", "u_name", "d_value", "d_year"]);
                    }
                    var langUpdatedData = []

                    //console.log("UPDATED DATA",updatedData)
                    updatedData.forEach(log => {

                        let entry = {};
                        Object.keys(log).forEach(key => {
                            if (!isNaN(key)) {  
                                entry[key] = log[key];  
                            }
                        });

                        entry.c_code = log.c_code;
                        entry.c_name = log.c_name;
                        entry.cat_name = log.cat_name;
                        entry.d_date = log.d_date;
                        entry.formatted_d_value = log.formatted_d_value;
                        entry.ind_code = log.ind_code;
                        entry.ind_name = log.ind_name;
                        entry.key = log.key;
                        entry.u_code = log.u_code;
                        entry.u_name = log.u_name;

                        console.log(entry);
                        langUpdatedData.push(entry);
                    });
                    console.log("LANG", langUpdatedData);
                    setSearchResults(langUpdatedData);
                    setYearRange(formattedYears);
                    setcolYearView(!lastYearAvailable);
                    setExportData(langUpdatedData);
                    setLoading(false);
                    setShowTable(true);
                });
            }
            
        }
    };

    const handleSearchCountry = (value) => {
        setSearchCountryValue(value);

        const filtered = Object.values(countriesState.value).sort((a, b) => a.c_short_name_eng.localeCompare(b.c_short_name_eng)).filter(country => {
            const countryName = country.c_short_name_eng || '';
            const lowerCaseValue = value.toLowerCase();
            return countryName.toLowerCase().includes(lowerCaseValue);
        });

        setFilteredCountries(filtered);
    }

    const exportToExcel = () => {
        const formattedYears = [];
        selectedYears.forEach(item => {
            if (item && item.hasOwnProperty('year')) { // Check if the item has a 'year' property
                formattedYears.push(item.year);
            } else {
                console.warn('Invalid item in selectedYears:', item);
            }
        });
        const formattedCountries = selectedCountries.map(country => country.split(' - ')[0]);
        //const formattedIndicators = selectedIndicators.map(indicator => indicator.split(' - ')[0]);
        const formattedIndicators = selectedIndicators;

        AxiosOICStat.post('/data/query-csv', {
            countries: formattedCountries,
            indicators: formattedIndicators,
            years: lastYearAvailable ? [-1] : formattedYears,
            formatOptions: {
                indicatorOrder: indicatorOrder,
                yearOrder: yearOrder,
                decimalSymbol: decimalSymbol,
                decimalPlaces: decimalPlaces
            }
        }).then(response => {
            let updatedData = lastYearAvailable ? searchResults : handleDataFormat(response.data, true);

            console.log(updatedData);

            // Year order for the columns
            if (yearOrder === 'Descending') {
                formattedYears.reverse();
            }

            // Dynamically construct headers based on the fixed fields and possibly only one year, the last available one
            const headers = lastYearAvailable
                ? ["Country", "Category", "Indicator", "Unit", "Data", "Year"]
                : ["Country", "Category", "Indicator", "Unit", ...formattedYears.map(year => year.toString())];

            // Map response data to the format suitable for Excel export
            const dataForExport = lastYearAvailable
                ? updatedData.map(item => ({
                    "Country": item.c_name,
                    "Category": `"${item.cat_name}"`,
                    "Indicator": `"${item.ind_name}"`,
                    "Unit": `"${item.u_name}"`,
                    "Data": item.formatted_d_value !== null ? `"${item.formatted_d_value}"` : "\" \"",
                    "Year": `"${item.d_year}"`
                }))
                : updatedData.map(item => {
                    const row = {
                        "Country": item.c_name,
                        "Category": `"${item.category}"`,
                        "Indicator": `"${item.ind_name}"`,
                        "Unit": `"${item.unit}"`
                    };
                    // Add dynamic year data
                    item.years.forEach((value, index) => {
                        if (decimalSymbol === ',') {
                            value = value.replace(',', ' ').replace('.', ',').replace(' ', '.');
                        }
                        const year = formattedYears[index];
                        row[year] = value !== null ? `"${value}"` : "\" \""; // Replace null with space for Excel
                    });
                    return row;
                });

            // Convert dataForExport to CSV string and include UTF-8 BOM
            const BOM = '\uFEFF';
            const csvContent = BOM + [
                headers.join(','), // Add the headers row
                ...dataForExport.map(row => headers.map(header => row[header]).join(',')) // Map each row to CSV format
            ].join(',\n');

            // Trigger download of the CSV file
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'oicstat_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });


    };



    const fetchRegionCountries = async (regionId) => {
        try {
            const response = await AxiosOICStat.get(`/regions-countries/${regionId}`); // Adjust the endpoint as needed
            return response.data; // Assuming the response body will be the array of country codes
        } catch (error) {
            console.error('Failed to fetch region countries:', error);
            // Handle errors, e.g., show notification
        }
    };

    const handleTitleClick = (event, node) => {
        event.stopPropagation();
        const wasCheckboxClicked = event.nativeEvent.target.closest('.ant-tree-checkbox');
        if (wasCheckboxClicked) {
            // If the checkbox was clicked, let the default onCheck behavior handle it
            return;
        }

        // Toggle expansion if it was not the checkbox that was clicked
        const newExpandedKeys = expandedKeys.includes(node.key)
            ? expandedKeys.filter(expandedKey => expandedKey !== node.key)
            : [...expandedKeys, node.key];
        setExpandedKeys(newExpandedKeys);
    };

    const handleCountryInfo = (event, countryCode) => {
        event.preventDefault();
        const url = `https://www.sesric.org/cif.php?c_code=${countryCode}`;
        window.open(url, '_blank');
    }

    const handleRegionChange = (e, region) => {
        const isChecked = e.target.checked;
        fetchRegionCountries(region.id).then(countries => {
            let updatedCountries = countries.map(fetchedCountry => {
                let country = Object.values(countriesState.value).find(stateCountry => stateCountry.c_code === fetchedCountry.country_id);
                return country ? `${fetchedCountry.country_id} - ${country.c_short_name_eng}` : '';
            }).filter(country => country !== '');

            let differenceCountries = [];

            setCountryMap(prevMap => {
                const newMap = { ...prevMap };
                updatedCountries.forEach(country => {
                    if (isChecked) {
                        // If the country is not in the map or is 0, it's being added
                        if (!newMap[country] || newMap[country] === 0) {
                            newMap[country] = 1;
                            differenceCountries.push(country);
                        } else {
                            // Increment count for already present countries
                            newMap[country] += 1;
                        }
                    } else {
                        // Decrement count for the country, and add to differences if it reaches zero
                        if (newMap[country] > 1) {
                            newMap[country] -= 1;
                        } else {
                            newMap[country] = 0;
                            differenceCountries.push(country);
                        }
                    }
                });
                return newMap;
            });

            setSelectedRegions(prev => isChecked ? [...prev, region.id] : prev.filter(id => id !== region.id));

            // Handle updating selected countries by adding or removing from the current list
            setSelectedCountries(prev => {
                if (isChecked) {
                    // Merge new countries into the existing list, ensuring no duplicates
                    return [...new Set([...prev, ...updatedCountries])];
                } else {
                    // Remove difference countries from the current list
                    return prev.filter(country => !differenceCountries.includes(country));
                }
            });
        });
    };

    const regions = regionMappingsData
        .filter(region => region.is_visible)
        .map(region => ({
            id: region.id,
            name: region.region_name,
            exp_en: region.region_exp_en,
            category: region.region_category
    }));

    const regionsByCategory = useMemo(() => {
        const grouped = {
          geographical: [],
          income: [],
          oic: []
        };
      
        regions.forEach(region => {
          if (grouped[region.category]) {
            grouped[region.category].push(region);
          }
        });
      
        return grouped;
      }, [regions]);
      
      const regionsRow1 = useMemo(() => {
        return [...regionsByCategory.geographical].sort((a, b) => a.name.localeCompare(b.name));
        }, [regionsByCategory]);

        const regionsRow2 = useMemo(() => {
            return [...regionsByCategory.income].sort((a, b) => a.name.localeCompare(b.name));
        }, [regionsByCategory]);

        const regionsRow3 = useMemo(() => {
            return [...regionsByCategory.oic].sort((a, b) => a.name.localeCompare(b.name));
        }, [regionsByCategory]);    

    //console.log(selectedRegions);
    const renderRegionItem = (region) => (
        region &&
        <Checkbox
            key={region.id}
            checked={selectedRegions.includes(region.id)}
            onChange={(e) => handleRegionChange(e, region)}
            value={region.id} 
            style={{ whiteSpace: 'nowrap', minWidth: '205px'}}
            >   
            {region.name}
            <Tooltip title={region.exp_en}>
                <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
        </Checkbox>
    );

    const renderCountryItem = (country) => {
        const langSuffix = language === 'fr' ? '_fr' : language === 'ar' ? '_arb' : '_eng'; 
        const countryName = country[`c_short_name${langSuffix}`]; 
    
        return (
            country.c_code > 0 &&
            <Checkbox value={`${country.c_code} - ${countryName}`}>
                {countryName}
                <InfoCircleOutlined onClick={(e) => handleCountryInfo(e, country.c_code)} style={{ marginLeft: 5 }} />
            </Checkbox>
        );
    };

    const renderYearItem = (year) => (
        <Checkbox
            key={year}
            value={year}
            checked={selectedYears.some(item => item.year === year)}
            onChange={(e) => onYearChange(e.target.checked, year)}
            disabled={lastYearAvailable}
        >
            {year}
        </Checkbox>
    );

    const responsiveColumns = {
        xl: 6,
        lg: 4,
        md: 3,
        sm: 2,
        xs: 1
    };
    return (
        <>
        {!auto_search && (
            <Row style={{ backgroundColor: 'white', margin: '10px', padding: '10px', marginBottom: '20px', borderRadius: '10px' }}>
                <Text style={textStyle}>
                    {t('query.instructions')}
                </Text>
                <Collapse accordion size='small' style={{ width: '100%' }}>
                    <Panel
                        header={
                            <>
                                <span>
                                    {t('query.countries')}
                                </span>
                                {selectedCountries.length > 0 && (
                                    <span style={{ marginLeft: 10, fontWeight: 'normal' }}>
                                        {selectedCountries
                                            .sort((a, b) => a.split(' - ')[1].localeCompare(b.split(' - ')[1]))
                                            .slice(0, 5)
                                            .map(country => country.split(' - ')[1])
                                            .join(', ')
                                        }
                                        {selectedCountries.length > 5 && ` + ${selectedCountries.length - 5} more`}
                                    </span>
                                )}
                            </>
                        }
                        key="1"
                        style={{ ...customPanelStyle }}>
                        <Checkbox.Group style={{ width: '100%', fontStyle: 'italic', fontWeight: '300' }} value={selectedRegions} onChange={setSelectedRegions}>
                            <Row>
                                <Row style={{ marginBottom: "5px" }}>
                                    <Col xs={2} sm={4} md={4} lg={4} xl={4}>
                                        {regions && renderRegionItem(regions.find(region => region.id === 1))}
                                    </Col>
                                </Row>
                                <Col xs={22} sm={20} md={20} lg={20} xl={20}>
                                    <Row style={{ marginBottom: "5px" }}>
                                        <Col span={24}>
                                            <Title level={5}>{t('query.by_oic_group')}</Title>
                                            <ColumnBasedLayout
                                                items={regionsRow3}
                                                renderItem={renderRegionItem}
                                                isJustifyStart={false}
                                                rowBasedLayout={true}
                                                responsiveColumns={{ xs: 12, sm: 8, md: 7, lg: 7, xl: 7 }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: "5px" }}>
                                        <Col span={24}>
                                            <Title level={5}>{t('query.by_income_group')}</Title>
                                            <ColumnBasedLayout
                                                items={regionsRow2}
                                                renderItem={renderRegionItem}
                                                isJustifyStart={false}
                                                rowBasedLayout={true}
                                                responsiveColumns={{ xs: 12, sm: 8, md: 7, lg: 7, xl: 7 }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Title level={5}>{t('query.by_geographical_group')}</Title>
                                            <ColumnBasedLayout
                                                items={regionsRow1}
                                                renderItem={renderRegionItem}
                                                isJustifyStart={false}
                                                rowBasedLayout={true}
                                                responsiveColumns={{ xs: 12, sm: 8, md: 7, lg: 7, xl: 7 }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Checkbox.Group>
                        <Divider />
                        <Search
                            placeholder={t('query.search_countries')}
                            allowClear
                            value={searchCountryValue}
                            onChange={(e) => handleSearchCountry(e.target.value)}
                            style={{ marginBottom: 10 }}
                        />
                        <Checkbox.Group style={{ width: '100%' }} value={selectedCountries} onChange={setSelectedCountries}>
                            {filteredCountries.length > 0 ? (
                                <ColumnBasedLayout
                                    items={filteredCountries}
                                    renderItem={renderCountryItem}
                                    responsiveColumns={responsiveColumns}
                                />
                            ) : (
                                <ColumnBasedLayout
                                    items={Object.values(countriesState.value).sort((a, b) => {
                                        // Determine the name based on the selected language
                                        const aName = language === 'fr' ? a.c_short_name_fr :
                                                    language === 'ar' ? a.c_short_name_arb : 
                                                    a.c_short_name_eng; 
                                                    
                                        const bName = language === 'fr' ? b.c_short_name_fr :
                                                    language === 'ar' ? b.c_short_name_arb : 
                                                    b.c_short_name_eng;
                                                    
                                        return aName.localeCompare(bName);
                                    })}
                                    renderItem={renderCountryItem}
                                    responsiveColumns={responsiveColumns}
                                />
                            )}
                        </Checkbox.Group>
                    </Panel>

                    <Panel
                        header={
                            <>
                                <span>
                                    {t('query.categories_and_indicators')}
                                </span>
                                {selectedIndicators.length > 0 && (
                                    <span style={{ marginLeft: 10, fontWeight: 'normal' }}>
                                        {`${selectedIndicators.length} ${selectedIndicators.length === 1 ? t('query.indicator_is') : t('query.indicators_are')} ${t('query.selected')}`}
                                    </span>
                                )}
                            </>
                        }
                        key="2"
                        style={customPanelStyle}>
                        <Search
                            placeholder={t('query.search')}
                            allowClear
                            value={searchValue}
                            onChange={(e) => handleTreeSearch(e, e.target.value)}
                            style={{ marginBottom: 10 }}
                        />
                        <Tree
                            checkable
                            checkedKeys={indicatorKeys}
                            expandedKeys={expandedKeys}
                            onExpand={onExpand}
                            onClick={handleTitleClick}
                            onCheck={onCheck}
                            treeData={treeData}
                            motion={0}
                            showLine
                        />
                    </Panel>

                    <Panel
                        header={
                            <>
                                <span>
                                    {t('query.years')}
                                </span>
                                {selectedYears.length > 0 && (
                                    <span style={{ marginLeft: 10, fontWeight: 'normal' }}>
                                        {selectedYears
                                            .sort((a, b) => a.year - b.year)
                                            .slice(0, 5)
                                            .map(item => item.year)
                                            .join(', ')
                                        }
                                        {selectedYears.length > 5 && ` + ${selectedYears.length - 5} more`}
                                    </span>
                                )}
                            </>
                        }
                        key="3"
                        style={customPanelStyle}
                    >
                        <div style={{ fontWeight: '300' }} className="years-checkboxes">
                            <Checkbox
                                onChange={(e) => {
                                    setSelectedYears(e.target.checked ? yearsList.map(y => ({ year: y })) : []);
                                    setLastYearAvailable(false);
                                }}
                                checked={selectedYears.length === yearsList.length}
                            >
                                {t('query.select_all')}
                            </Checkbox>
                            <Checkbox
                                onChange={(e) => { 
                                    const isChecked = e.target.checked;
                                    setLastYearAvailable(isChecked); 
                                    setSelectedYears([]);
                                }}
                                checked={lastYearAvailable}
                            >
                                {t('query.last_year_available')}
                            </Checkbox>
                            <Checkbox
                                onChange={(e) => { setSelectedYears(e.target.checked ? yearsList.slice(0, 5).map(year => ({ year })) : []); setLastYearAvailable(false); }}
                                checked={selectedYears.length === 5 &&
                                    yearsList.slice(0, 5).every(year => selectedYears.some(y => y.year === year))}
                            >
                                {t('query.last_5_years')}
                            </Checkbox>
                            <Checkbox
                                onChange={(e) => { setSelectedYears(e.target.checked ? yearsList.slice(0, 10).map(year => ({ year })) : []); setLastYearAvailable(false); }}
                                checked={selectedYears.length === 10 &&
                                    yearsList.slice(0, 10).every(year => selectedYears.some(y => y.year === year))}
                            >
                                {t('query.last_10_years')}
                            </Checkbox>
                            <Divider style={{ margin: '5px' }} />
                            <ColumnBasedLayout
                                items={yearsList}
                                renderItem={renderYearItem}
                                responsiveColumns={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                                isJustifyStart={true}
                            />
                        </div>
                    </Panel>

                    <Panel header={t('query.format')} key="4" style={customPanelStyle}>
                        <div style={selectStyle}>
                            <Text style={selectTitleStyle} strong>{t('query.indication_order')}:</Text>
                            <Select defaultValue="by Country then Indicator" style={{ width: '100%' }} onChange={setIndicatorOrder}>
                                <Option value="by Country then Indicator">{t('query.by_country_then_indicator')}</Option>
                                <Option value="by Indicator then Country">{t('query.by_indicator_then_country')}</Option>
                            </Select>
                        </div>

                        <div style={selectStyle}>
                            <Text style={selectTitleStyle} strong>{t('query.year_order')}:</Text>
                            <Select defaultValue={yearOrder} style={{ width: '100%' }} onChange={setYearOrder}>
                                <Option value="Ascending">{t('query.ascending')}</Option>
                                <Option value="Descending">{t('query.descending')}</Option>
                            </Select>
                        </div>

                        <div style={selectStyle}>
                            <Text style={selectTitleStyle} strong>{t('query.decimal_symbol')}:</Text>
                            <Select defaultValue="." style={{ width: '100%' }} onChange={setDecimalSymbol}>
                                <Option value=".">{t('query.dot')}</Option>
                                <Option value=",">{t('query.comma')}</Option>
                            </Select>
                        </div>

                        <div style={selectStyle}>
                            <Text style={selectTitleStyle} strong>{t('query.decimal_places')}:</Text>
                            <Select defaultValue={2} style={{ width: '100%' }} onChange={setDecimalPlaces}>
                                <Option value={0}>0</Option>
                                <Option value={1}>1</Option>
                                <Option value={2}>2</Option>
                                <Option value={3}>3</Option>
                                <Option value={4}>4</Option>
                            </Select>
                        </div>
                    </Panel>
                </Collapse>

                <Button type="primary" onClick={handleSearch} disabled={loading} style={{ margin: '2px 10px 10px 10px', padding: '0px 30px', ...customPanelStyle }}>
                    {loading ? t('query.loading') : t('query.submit')}
                </Button>
                {searchResults.length !== 0 && (
                    <Button onClick={exportToExcel} type="primary" style={{ margin: '2px 10px 10px 10px' }}>
                        {t('query.export_to_excel')}
                    </Button>
                )}
            </Row>
        )}
        <Row>
            <Col xs={24}>
                {searchResults.length !== 0 && (
                    <DataTable
                        className={"custom-table"}
                        data={searchResults}
                        columns={cols}
                        widths={['10%', '30%', '10%', '10%', '35%', '50%', '60%']}
                        loading={loading}
                        decimalSymbol={decimalSymbol}
                        decimalPlaces={decimalPlaces}
                        colYearView={colYearView}
                        yearRange={yearRange}
                        yearOrder={yearOrder}
                        showIndexColumn={false}
                        isAdmin={false}
                    />
                )}
            </Col>
        </Row>
        <IndicatorShowExternal
            visible={showModalVisible}
            onCancel={handleShowModalCancel}
            record={selectedRecord}
        />
        <GoalSelectionModal
            visible={goalSelectionVisible}
            onCancel={() => setGoalSelectionVisible(false)}
            onOk={handleGoalSelectionOk}
        />
    </>
    );
};